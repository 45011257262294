import React from "react";

import {Image} from 'react-bootstrap';

import Home from "../../img/header.png";

import Pro from "../../img/logoBranco.png";

import "./style.css";

export default function Header({local='home'}){

    return (

        local == 'home' ? 
        <div>
            <Image src={Home} fluid className="expoanimal"/>
        </div>
        :
        <div>
            <Image src={Pro} fluid className="Logo"/>
        </div>
    )
}