import React from "react";
import { Form } from "react-bootstrap";

export default function InputConcelhos({funcao, variable}){
    return ( 
        
        <Form.Group>
            <Form.Label className="label" >Concelho</Form.Label>
            <Form.Select onChange={funcao} value={variable} className="inputform">
                <option defaultValue value="" >-- escolher --</option>
                <option value="519">Abrantes</option>
                <option value="465">Aguiar da Beira</option>
                <option value="553">Alandroal</option>
                <option value="427">Albergaria-a-Velha</option>
                <option value="590">Albufeira</option>
                <option value="520">Alcanena</option>
                <option value="502">Alcobaça</option>
                <option value="526">Alcochete</option>
                <option value="591">Alcoutim</option>
                <option value="533">Alcácer do Sal</option>
                <option value="509">Alenquer</option>
                <option value="379">Alfândega da Fé</option>
                <option value="370">Alijó</option>
                <option value="592">Aljezur</option>
                <option value="567">Aljustrel</option>
                <option value="626">Almada</option>
                <option value="486">Almeida</option>
                <option value="580">Almeirim</option>
                <option value="568">Almodôvar</option>
                <option value="581">Alpiarça</option>
                <option value="538">Alter do Chão</option>
                <option value="451">Alvaiázere</option>
                <option value="569">Alvito</option>
                <option value="617">Amadora</option>
                <option value="357">Amarante</option>
                <option value="335">Amares</option>
                <option value="428">Anadia</option>
                <option value="400">Angra do Heroísmo</option>
                <option value="452">Ansião</option>
                <option value="325">Arcos de Valdevez</option>
                <option value="453">Arganil</option>
                <option value="630">Armamar</option>
                <option value="415">Arouca</option>
                <option value="554">Arraiolos</option>
                <option value="539">Arronches</option>
                <option value="510">Arruda dos Vinhos</option>
                <option value="429">Aveiro</option>
                <option value="540">Avis</option>
                <option value="582">Azambuja</option>
                <option value="358">Baião</option>
                <option value="336">Barcelos</option>
                <option value="570">Barrancos</option>
                <option value="527">Barreiro</option>
                <option value="446">Batalha</option>
                <option value="571">Beja</option>
                <option value="499">Belmonte</option>
                <option value="583">Benavente</option>
                <option value="511">Bombarral</option>
                <option value="555">Borba</option>
                <option value="380">Boticas</option>
                <option value="337">Braga</option>
                <option value="381">Bragança</option>
                <option value="359">Cabeceiras de Basto</option>
                <option value="512">Cadaval</option>
                <option value="513">Caldas da Rainha</option>
                <option value="635">Calheta [R.A.A.]</option>
                <option value="606">Calheta [R.A.M.]</option>
                <option value="326">Caminha</option>
                <option value="541">Campo Maior</option>
                <option value="438">Cantanhede</option>
                <option value="631">Carrazeda de Ansiães</option>
                <option value="466">Carregal do Sal</option>
                <option value="584">Cartaxo</option>
                <option value="618">Cascais</option>
                <option value="454">Castanheira de Pêra</option>
                <option value="542">Castelo  de Vide</option>
                <option value="495">Castelo Branco</option>
                <option value="360">Castelo de Paiva</option>
                <option value="467">Castro Daire</option>
                <option value="593">Castro Marim</option>
                <option value="572">Castro Verde</option>
                <option value="487">Celorico da Beira</option>
                <option value="361">Celorico de Basto</option>
                <option value="585">Chamusca</option>
                <option value="382">Chaves</option>
                <option value="413">Cinfães</option>
                <option value="439">Coimbra</option>
                <option value="440">Condeixa-a-Nova</option>
                <option value="521">Constância</option>
                <option value="586">Coruche</option>
                <option value="411">Corvo</option>
                <option value="500">Covilhã</option>
                <option value="543">Crato</option>
                <option value="573">Cuba</option>
                <option value="607">Câmara de Lobos</option>
                <option value="544">Elvas</option>
                <option value="522">Entroncamento</option>
                <option value="412">Espinho</option>
                <option value="338">Esposende</option>
                <option value="430">Estarreja</option>
                <option value="556">Estremoz</option>
                <option value="341">Fafe</option>
                <option value="594">Faro</option>
                <option value="362">Felgueiras</option>
                <option value="574">Ferreira do Alentejo</option>
                <option value="504">Ferreira do Zêzere</option>
                <option value="441">Figueira da Foz</option>
                <option value="488">Figueira de Castelo Rodrigo</option>
                <option value="455">Figueiró dos vinhos</option>
                <option value="483">Fornos de Algodres</option>
                <option value="632">Freixo de Espada à Cinta</option>
                <option value="545">Fronteira</option>
                <option value="608">Funchal</option>
                <option value="501">Fundão</option>
                <option value="546">Gavião</option>
                <option value="587">Golegã</option>
                <option value="349">Gondomar</option>
                <option value="484">Gouveia</option>
                <option value="534">Grândola</option>
                <option value="489">Guarda</option>
                <option value="342">Guimarães</option>
                <option value="456">Góis</option>
                <option value="408">Horta</option>
                <option value="496">Idanha-a-Nova</option>
                <option value="595">Lagoa</option>
                <option value="394">Lagoa [R.A.A.]</option>
                <option value="596">Lagos</option>
                <option value="409">Lajes das Flores</option>
                <option value="405">Lajes do Pico</option>
                <option value="371">Lamego</option>
                <option value="447">Leiria</option>
                <option value="619">Lisboa</option>
                <option value="597">Loulé</option>
                <option value="620">Loures</option>
                <option value="514">Lourinhã</option>
                <option value="363">Lousada</option>
                <option value="457">Lousã</option>
                <option value="383">Macedo de Cavaleiros</option>
                <option value="609">Machico</option>
                <option value="629">Madalena</option>
                <option value="406">Madela</option>
                <option value="621">Mafra</option>
                <option value="350">Maia</option>
                <option value="468">Mangualde</option>
                <option value="490">Manteigas</option>
                <option value="364">Marco de Canaveses</option>
                <option value="448">Marinha Grande</option>
                <option value="547">Marvão</option>
                <option value="351">Matosinhos</option>
                <option value="507">Mação</option>
                <option value="432">Mealhada</option>
                <option value="327">Melgaço</option>
                <option value="372">Mesão Frio</option>
                <option value="442">Mira</option>
                <option value="458">Miranda do Corvo</option>
                <option value="384">Miranda do Douro</option>
                <option value="385">Mirandela</option>
                <option value="386">Mogadouro</option>
                <option value="419">Moimenta da Beira</option>
                <option value="528">Moita</option>
                <option value="598">Monchique</option>
                <option value="365">Mondim de Basto</option>
                <option value="548">Monforte</option>
                <option value="387">Montalegre</option>
                <option value="558">Montemor-o-Novo</option>
                <option value="443">Montemor-o-Velho</option>
                <option value="529">Montijo</option>
                <option value="328">Monção</option>
                <option value="549">Mora</option>
                <option value="469">Mortágua</option>
                <option value="576">Moura</option>
                <option value="559">Mourão</option>
                <option value="433">Murtosa</option>
                <option value="388">Murça</option>
                <option value="575">Mértolo</option>
                <option value="491">Mêda</option>
                <option value="503">Nazaré</option>
                <option value="470">Nelas</option>
                <option value="550">Nisa</option>
                <option value="395">Nordeste</option>
                <option value="535">Odemira</option>
                <option value="622">Odivelas</option>
                <option value="623">Oeiras</option>
                <option value="508">Oleiros</option>
                <option value="599">Olhão</option>
                <option value="416">Oliveira de Azeméis</option>
                <option value="471">Oliveira de Frades</option>
                <option value="434">Oliveira do Bairro</option>
                <option value="459">Oliveira do Hospital</option>
                <option value="577">Ourique</option>
                <option value="505">Ourém</option>
                <option value="435">Ovar</option>
                <option value="530">Palmela</option>
                <option value="460">Pampilhosa da Serra</option>
                <option value="367">Paredes</option>
                <option value="329">Paredes de Coura</option>
                <option value="366">Paços de Ferreira</option>
                <option value="461">Pedrogão Grande</option>
                <option value="444">Penacova</option>
                <option value="368">Penafiel</option>
                <option value="472">Penalva do Castelo</option>
                <option value="497">Penamacor</option>
                <option value="420">Penedono</option>
                <option value="462">Penela</option>
                <option value="516">Peniche</option>
                <option value="373">Peso de Régua</option>
                <option value="492">Pinhel</option>
                <option value="449">Pombal</option>
                <option value="396">Ponta Delgada</option>
                <option value="610">Ponta do Sol</option>
                <option value="330">Ponte da Barca</option>
                <option value="331">Ponte de Lima</option>
                <option value="551">Ponte de Sor</option>
                <option value="552">Portalegre</option>
                <option value="560">Portel</option>
                <option value="600">Portimão</option>
                <option value="352">Porto</option>
                <option value="611">Porto Moniz</option>
                <option value="616">Porto Santo</option>
                <option value="450">Porto de Mós</option>
                <option value="397">Povoação</option>
                <option value="401">Praia da Vitória</option>
                <option value="480">Proença-a-Nova</option>
                <option value="343">Póvoa de Lanhoso</option>
                <option value="353">Póvoa de Varzim</option>
                <option value="561">Redondo</option>
                <option value="562">Reguengos de Monsaraz</option>
                <option value="414">Resende</option>
                <option value="612">Ribeira Brava</option>
                <option value="398">Ribeira Grande</option>
                <option value="369">Ribeira de Pena</option>
                <option value="588">Rio Maior</option>
                <option value="374">Sabrosa</option>
                <option value="493">Sabugal</option>
                <option value="634">Salvaterra de Magos</option>
                <option value="473">Santa Comba Dão</option>
                <option value="613">Santa Cruz</option>
                <option value="402">Santa Cruz da Graciosa</option>
                <option value="410">Santa Cruz das Flores</option>
                <option value="417">Santa Maria da Feira</option>
                <option value="375">Santa Marta de Penaguião</option>
                <option value="614">Santana</option>
                <option value="589">Santarém</option>
                <option value="536">Santiago do Cacém</option>
                <option value="344">Santo Tirso</option>
                <option value="523">Sardoal</option>
                <option value="485">Seia</option>
                <option value="628">Seixal</option>
                <option value="422">Sernancelhe</option>
                <option value="578">Serpa</option>
                <option value="481">Sertã</option>
                <option value="531">Sesimbra</option>
                <option value="532">Setúbal</option>
                <option value="436">Sever do Vouga</option>
                <option value="602">Silves</option>
                <option value="537">Sines</option>
                <option value="624">Sintra</option>
                <option value="517">Sobral de Monte Agraço</option>
                <option value="445">Soure</option>
                <option value="563">Sousel</option>
                <option value="475">Sátão</option>
                <option value="601">São Brás de Alportel</option>
                <option value="633">São João da Madeira</option>
                <option value="421">São João da Pesqueira</option>
                <option value="474">São Pedro do Sul</option>
                <option value="407">São Roque do Pico</option>
                <option value="615">São Vicente</option>
                <option value="423">Tabuaço</option>
                <option value="424">Tarouca</option>
                <option value="603">Tavira</option>
                <option value="339">Terras de Bouro</option>
                <option value="506">Tomar</option>
                <option value="476">Tondela</option>
                <option value="376">Torre de Moncorvo</option>
                <option value="524">Torres Novas</option>
                <option value="518">Torres Vedras</option>
                <option value="494">Trancoso</option>
                <option value="345">Trofa</option>
                <option value="463">Tábua</option>
                <option value="636">Tábua</option>
                <option value="437">Vagos</option>
                <option value="418">Vale de Cambra</option>
                <option value="332">Valença</option>
                <option value="354">Valongo</option>
                <option value="389">Valpaços</option>
                <option value="404">Velas</option>
                <option value="564">Vendas Novas</option>
                <option value="565">Viana do Alentejo</option>
                <option value="333">Viana do Castelo</option>
                <option value="579">Vidigueira</option>
                <option value="346">Vieira do Minho</option>
                <option value="377">Vila Flor</option>
                <option value="625">Vila Franca de Xira</option>
                <option value="399">Vila Franca do Campo</option>
                <option value="464">Vila Nova Poiares</option>
                <option value="525">Vila Nova da Barquinha</option>
                <option value="334">Vila Nova de Cerveira</option>
                <option value="347">Vila Nova de Famalicão</option>
                <option value="425">Vila Nova de Foz Côa</option>
                <option value="356">Vila Nova de Gaia</option>
                <option value="477">Vila Nova de Paiva</option>
                <option value="390">Vila Pouca de Aguiar</option>
                <option value="378">Vila Real</option>
                <option value="605">Vila Real de Santo António</option>
                <option value="498">Vila Velha de Ródão</option>
                <option value="340">Vila Verde</option>
                <option value="566">Vila Viçosa</option>
                <option value="482">Vila de Rei</option>
                <option value="604">Vila do Bispo</option>
                <option value="355">Vila do Conde</option>
                <option value="393">Vila do Porto</option>
                <option value="391">Vimioso</option>
                <option value="392">Vinhais</option>
                <option value="478">Viseu</option>
                <option value="348">Vizela</option>
                <option value="479">Vouzela</option>
                <option value="426">Águeda</option>
                <option value="557">Évora</option>
                <option value="431">Ílhavo</option>
                <option value="515">Óbidos</option>
            </Form.Select>
        </Form.Group>
            
    )
}