
class Tools {

    constructor() {}

    offlineAvailable = () => {
        var mode = localStorage.getItem("offlineMode",true);

        if (mode == "true"){
            return true;
        }
        return false;
    }

}

export default new Tools();