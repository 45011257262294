import React, { useState } from "react";
import {Card, Form, Button, Alert, Row, Col, FormCheck} from "react-bootstrap";
import InputLabel from "../InputLabel";
import InputConcelhos from "../InputLabel/InputConcelhos";

import petfillingAPI from "../../api";

import Header from '../Header'

//Importaçao de estilos
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'

//Importação de imagens
import { Link, useNavigate } from "react-router-dom";

const GoldCheckForm = ({label, name, onClick}) => {
    const idCheckForm = (Math.random() + 1)
    return (
        <FormCheck>
            <FormCheck.Input id={name+idCheckForm} multiple={true} name="option" type="checkbox" onClick={onClick} className="radioColor" />
            <FormCheck.Label htmlFor={name+idCheckForm} className="radioLabel">{label}</FormCheck.Label>
        </FormCheck>
    )
}

export default function Breeder() {
    const timer = null;
    let dev = null;
    const navigate = useNavigate();
    if (process.env.NODE_ENV=="development"){
        dev = true;
    }   else    {
        dev = false;
    }
    clearInterval(timer);
    const _LAYOUT_DATA_ = 'dados';
    const _LAYOUT_OPTIONS_ = 'options';
    const [name, setName] = useState(!dev ? "":"Tiago");
    const [apelido, setApelido] = useState(!dev ? "":"Silva");
    const [email, setEmail] = useState(!dev ? "":"tiago.silva@goldpet.pt");
    const [tel, setTel] = useState(!dev ? "":"916715542");
    const [concelho, setConcelho] = useState("");
    const [formErro, setErro] = useState("");
    const [showErro, setShowErro] = useState(false);
    const [formSucesso, setSucesso] = useState("");
    const [showSucesso, setShowSucesso] = useState(false);
    const [option, setOption] = useState([]);
    const [layout, setLayout] = useState(_LAYOUT_DATA_)

    function validarForm(){
        setErro("");
        setShowErro(false);

        if(name.trim().length<3){
            setErro("O campo nome é inválido");
            setShowErro(true);
            return false;
        }
        if(apelido.trim().length<3){
            setErro("O campo nome é inválido");
            setShowErro(true);
            return false;
        }

        if (!validacaoEmail(email)){
            setErro("O campo e-mail é inválido");
            setShowErro(true);
            return false;
        }

        if (tel.trim().length<9){
            setErro("O campo Contacto Telefónico deve ter pelo menos 9 caracteres");
            setShowErro(true);
            return false;
        }

        if (concelho == ""){
            setErro("O campo Concelho deve ser seleccionado");
            setShowErro(true);
            return false;
        }

        setLayout(_LAYOUT_OPTIONS_)

        return true;
    }

    const validateNIF = (value) => {
        if (!value || typeof value !== "string" || value.length !== 9) return false;

        let sumAux = 0;
        for (let i = 9; i >= 2; i--) {
          sumAux += i * (parseInt(value[value.length - i]) || 0);
        }

        const module = sumAux % 11;

        // Get the eight first numbers
        const NIFwithoutLastDigit = value.slice(0, value.length - 1);

        if (module === 0 || module === 1) {
          return `${NIFwithoutLastDigit}0` === value;
        } else {
          return `${NIFwithoutLastDigit}${11 - module}` === value;
        }
      };

    function validacaoEmail(field) {
        var usuario = field.substring(0, field.indexOf("@"));
        var dominio = field.substring(field.indexOf("@")+ 1, field.length);

        if ((usuario.length >=1) &&
            (dominio.length >=3) &&
            (usuario.search("@")==-1) &&
            (dominio.search("@")==-1) &&
            (usuario.search(" ")==-1) &&
            (dominio.search(" ")==-1) &&
            (dominio.search(".")!=-1) &&
            (dominio.indexOf(".") >=1)&&
            (dominio.lastIndexOf(".") < dominio.length - 1)) {
            return true;
        }else{
            return false;
        }
    }

    async function submit() {

        //Valida se o utilizador escolheu alguma opção
        if(option === null){
            setShowErro(true);
            setErro('Escolha uma opção!')
            return false;
        }

        const data = {
            name: name,
            apelido: apelido,
            email: email,
            telemovel: tel,
            concelho: concelho,
            option: option.toString()
        }
        console.log(data)
        const petFilling = new petfillingAPI();

        const response = await petFilling.postData(data)
        if(response){
            clearForm()
        } else {
            setShowErro(true);
            setErro("Ocorreu um erro a comunicar o registo");
        }

           /* localStorage.setItem(name,JSON.stringify([name,apelido]))
            obj = new petfillingAPI()

            var novoRegisto = await obj.postCustomers({
                name: name,
                lastname: apelido,
                company: nomeFac,
                siret: NIF,
                email: email,
                group: tipoConta,
                passwd: passwd,
                telefone: tel,
                conselho: concelho,
                tipoCliente: "Criador"
            });
            if (novoRegisto === false){
                setShowErro(true);
                setShowSucesso(false);
                if (novoRegisto.errors !== undefined){
                    setErro(novoRegisto.errors[0].message);
                }   else    {
                    if (!offLineMode){
                        setErro("Ocorreu um erro a comunicar o registo");
                    }   else    {
                        setShowErro(false);
                        setShowSucesso(true);
                        setSucesso("Registo guardado");
                        timer=setInterval(clearForm, 1000);
                    }
                }
            }   else    {
                setShowErro(false);
                setShowSucesso(true);
                setSucesso("Registo guardado com o nrº "+novoRegisto.data.customer.id);
                timer=setInterval(clearForm, 1000);
            } */

    }

    function clearForm(){
        setName("");
        setApelido("");
        setEmail("");
        setConcelho("");
        setTel("");
        clearInterval(timer);
        navigate("/ThankYouCriador");
    }

    const handlerOptions = (opt) => {
        console.log(option.indexOf(opt))
        if(option.indexOf(opt) === -1){
            setOption( prevState => {
                return [...prevState, opt]
            })
        } else {
            const newArray = option.filter(n => n != opt)
            setOption(newArray)
        }
    }

    console.log(option)

    return(
        <div className="pageBreeder">

                <Header local='pro'/>
                { layout === _LAYOUT_DATA_ && <Card className="formMain">
                    <Card.Header className="formHeader">
                        insira os seus dados:
                    </Card.Header>
                    <Card.Body>

                        <Form className="formBody">

                            <Alert variant="danger" show={showErro}>
                                <Alert.Heading>Ocorreu um erro</Alert.Heading>
                                <p>{formErro}</p>
                            </Alert>

                            <Alert variant="success" show={showSucesso}>
                                <Alert.Heading>Registado com sucesso</Alert.Heading>
                                <p>{formSucesso}</p>
                            </Alert>

                            <InputLabel label="Nome Próprio" variable={name} funcao={e => setName(e.target.value)}/>
                            <InputLabel label="Apelido" variable={apelido} funcao={e => setApelido(e.target.value)} />
                            <InputLabel label="E-mail" tipo="email" variable={email} funcao={e => setEmail(e.target.value)}/>
                            <InputLabel label="Telemóvel" variable={tel} tipo="number" funcao={e => setTel(e.target.value)}/>
                            <InputConcelhos variable={concelho} funcao={e => setConcelho(e.target.value)}/>
                        </Form>
                    </Card.Body>
                    <Card.Footer className="cardFooter">
                        <Button variant="primary" className="inputsubmit" onClick={() => validarForm()}>Seguinte</Button>{' '}
                    </Card.Footer>
                </Card> }

                { layout === _LAYOUT_OPTIONS_ && <Card className="formMain">
                    <Card.Header className="formHeader">
                        escolha a opção que mais se adequa:
                    </Card.Header>
                    <Card.Body>
                        <Form className="formBody formBodyPadding">
                            <Alert variant="danger" show={showErro}>
                                <Alert.Heading>Ocorreu um erro</Alert.Heading>
                                <p>{formErro}</p>
                            </Alert>

                            <Alert variant="success" show={showSucesso}>
                                <Alert.Heading>Registado com sucesso</Alert.Heading>
                                <p>{formSucesso}</p>
                            </Alert>
                            <Row className="formRow">
                                <Col>
                                    <GoldCheckForm
                                        label="Criador de cães"
                                        name="option[]"
                                        onClick={() => handlerOptions('Criador de cães')}
                                    />
                                </Col>
                                <Col>
                                    <GoldCheckForm
                                        name="option[]"
                                        label="Criador de gatos"
                                        onClick={() => handlerOptions('Criador de gatos')}
                                    />
                                </Col>
                            </Row>
                            <Row className="formRow">
                                <Col>
                                    <GoldCheckForm
                                        name="option[]"
                                        label="Lojista"
                                        onClick={() => handlerOptions('Lojista')}
                                    />
                                </Col>
                                <Col>
                                    <GoldCheckForm
                                        name='option[]'
                                        label="Distribuidor"
                                        onClick={() => handlerOptions('Distribuidor')}
                                    />
                                </Col>
                            </Row>
                            <Row className="formRow">
                                <Col>
                                    <GoldCheckForm
                                        name='option[]'
                                        label="Consumidor Final (cão)"
                                        onClick={() => handlerOptions('Consumidor Final (cão)')}
                                    />
                                </Col>
                                <Col>
                                    <GoldCheckForm
                                        name='option[]'
                                        label="Consumidor Final (gato)"
                                        onClick={() => handlerOptions('Consumidor Final (gato)')}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                    <Card.Footer className="cardFooter">
                        <Button variant="primary" className="inputsubmit" onClick={() => submit()}>Guardar</Button>{' '}
                    </Card.Footer>
                </Card>}
                <Link to="/">
                    <div className="overlay">
                    </div>
                </Link>
        </div>
    )
}
