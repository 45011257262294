import React from "react";
import { Form } from "react-bootstrap";

export default function InputLabel({label="label", tipo="text", funcao, variable}){
    return (
        <Form.Group className="groupLine">
            <Form.Label className="label">{label}</Form.Label>
            <Form.Control className="inputform" type={tipo} onChange={funcao} value={variable}></Form.Control>
        </Form.Group>
    )
}