import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom';
import Breeder from './Components/Breeder';
import Intro from './Components/Intro'
import Profissional from './Components/Profissional';
import ThankYouProfissional from './Components/ThankYouProfissional';
import ThankYouBreeder from './Components/ThankYouBreeder';
import ConsumidorFinal from './Components/ConsumidorFinal';
import ThankYouConsumidorFinal from './Components/ThankYouConsumidorFinal';

import './App.css';

function App() {
  // <Route exact path="/" element={<Intro/>} />
  return (

    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Breeder/>} />
              {/* <Route exact path="/criador" element={<Breeder/>} /> */ }
            <Route exact path="/ThankYouCriador" element={<ThankYouBreeder/>} />
              {/* <Route exact path="/profissional" element={<Profissional/>} />
                <Route exact path="/ThankYouProfissional" element={<ThankYouProfissional/>} />
                <Route exact path="/consumidorFinal" element={<ConsumidorFinal/>} />
                <Route exact path="/ThankYouConsumidorFinal" element={<ThankYouConsumidorFinal/>} />
            */}
          </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
