import axios from "axios";


export default class petfillingAPI {

    constructor(){

        /* if (process.env.NODE_ENV=="production"){
            this.connect = axios.create({baseURL: 'http://petfilling.dev.goldpet.org/api/'})
        }   else    {
            this.connect = axios.create({baseURL: 'https://petfilling.pt/api/'})
        } */
        this.connect = axios.create({baseURL: 'https://petfilling.pt/'})

        this.auth = 'NFhYTkVTOUdXSVFLV0o5WENSUjlHRFpNVTdKMjFNTVc6';
    }

    marcarRegistoEnviado = (chave, email, id) => {
        var registos = JSON.parse(localStorage.getItem(chave));
        var i;
        for(i=0; i < registos.length; i++){
            if (registos[i].email == email){
                registos[i].enviado = id;
            }
        }
        //Para Eliminar um item do array
        //this.state.postList.filter(item => item.post_id != deletePostId
        localStorage.setItem(chave, JSON.stringify(registos));

    }

    postData = async (data) => {
       /* var registos = JSON.parse(localStorage.getItem(key));
        var key = "clt_"+name;
        if (registos == null){
            console.log("instanciar nova lista");
            registos = [];
        }
        registos.push({name, lastname, company, nif, email, concelho, tel})
        localStorage.setItem(key, JSON.stringify(registos)); */

        return await this.connect.post('module/goldapikiosk/postdata',{
            ...data,
            token: '4b83c256a7ee37fef090378006304e15',
            subtoken: 'fd1c44addbb563a0740a3beb74fe825d'
        },{
            "Content-Type": "text/plain",
            'Access-Control-Allow-Origin': '*',
        }). then( function(response){
            console.log('Resposta')
            return true
            /* if (Number.isInteger(response.data.customer.id )){
                (new petfillingAPI()).marcarRegistoEnviado(key,email,response.data.customer.id);
            }
            return response; */
        }).catch((error) => {
            return false;
        })

    }

    postCustomers = ({passwd,
        name, lastname, company,
        siret, email, group, telefone, concelho, tipoCliente,
        exhibition, animal, clube}) => {

        var key = "clt_"+tipoCliente;

        var registos = JSON.parse(localStorage.getItem(key));
        if (registos == null){
            console.log("instanciar nova lista");
            registos = [];
        }

        var cliente = {
            firstname: name,
            lastname: lastname,
            company: company,
            siret: siret,
            email: email,
            group: group,
            telefone: telefone,
            concelho: concelho,
            passwd: passwd,
            animal: animal,
            clube: clube,
            exhibition: exhibition,
            enviado: false
        };
        registos.push(cliente);

        localStorage.setItem(key, JSON.stringify(registos));


        var xml = '<?xml version=\"1.0\" encoding="UTF-8"?>'+
            '<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">'+
                '<customer>'+
                    `<passwd>${passwd}</passwd>`+
                    `<lastname>${lastname}</lastname>`+
                    `<firstname>${name}</firstname>`+
                    `<email>${email}</email>`+
                    `<id_gender>1</id_gender>`+
                    `<company>${company}</company>`+
                    `<telefone>${telefone}</telefone>`+
                    `<concelho>${concelho}</concelho>`+
                    `<siret>${siret}</siret>`+
                    `<note>${exhibition}</note>`+
                    `<id_default_group>${group}</id_default_group>`+
                    '<associations> '+
                        '<groups>'+
                            '<group>'+
                                `<id>${group}</id>`+
                            '</group>'+
                        '</groups>'+
                    '</associations>'+
                    `<clube>${clube}</clube>`+
                    `<animal>${animal}</animal>`+
                '</customer>'+
            '</prestashop>'

            console.log(xml);
        return this.connect.post('customers?output_format=JSON',xml,{
            headers: {
                Authorization: 'Basic '+this.auth,
            }
        }). then( function(response){
            if (Number.isInteger(response.data.customer.id )){
                (new petfillingAPI()).marcarRegistoEnviado(key,email,response.data.customer.id);
            }
            return response;

        }).catch((error) => {
            return false;
        })
    }

    checkIfRegistered(email){

        var res = true;
        var output = this.connect.get('customers?output_format=JSON&filter[email]='+email,{
            headers: {
                Authorization: 'Basic '+this.auth
            }
        }).then(function(response){
            if (response.status!=200){
               return false;
            }

            if (response.data.customers == 'undefined' || response.data.customers == null){
                return 0;
            }   else    {
                return response.data.customers.length;
            }

        }).catch((error) => {
            console.log('ERRO'+error)
            return false;
        })


        return output;
    };
}

