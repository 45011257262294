import React, {useEffect} from "react";
import Header from '../Header'

import './style.css'
import cao from './cao.png'
import sucesso from '../../img/registo.png'
import { useNavigate } from "react-router-dom";

function ThankYouProfissional() {
    let navigate = useNavigate();
    const timer=setInterval(returnToHome, 120000);

    useEffect(() => {
       window.scrollTo(0,0)
    },[])

    return (
        <div className="pageThankYouBreeder">
            <Header local='pro'/>
            <img src={sucesso} className="sucessoImagem"/>
            <div className="thankYouText">
                <p>Registo efetuado <br/> com sucesso.</p>
                <p className="pageP">Obrigado!</p>
            </div>
            <div className="caoBottomContainer">
                <img className="caoBottom" src={cao}/>
            </div>
        </div>
    );

    function returnToHome(){
        clearInterval(timer);
        navigate("/");
    }
  }

  export default ThankYouProfissional;
