import React from "react";
import Header from '../Header'

import styles from "./style.css";

import cao from './cao.png'
import { useNavigate, useLocation } from "react-router-dom";

function ThankYouProfissional() {
    let navigate = useNavigate();
    let local = useLocation();
    var timer=setInterval(returnToHome, 9000);
    return (
        
        <div className="page">
            <Header local='pro'/>
            <h1>
                OBRIGADO<br />PELO SEU REGISTO!
            </h1>
           
            <img className="caoBottom" src={cao}/>
        </div>
    );

    function returnToHome(){
        clearInterval(timer);
        navigate("/");
    }
  }
  
  export default ThankYouProfissional;
  